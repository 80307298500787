/** Based on legacy request function, when `stringifyError` is false */
export {};
export const isAxiosResponse = (response) => {
    return (response !== undefined &&
        response !== null &&
        typeof response === 'object' &&
        'status' in response &&
        'data' in response &&
        response.data !== undefined);
};
