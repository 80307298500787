import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { MultiSelect as XenMultiSelect } from 'xen-ui';
import { ErrorText } from '../../error-text';
import { FormMultiSelectValueRenderer } from './mutli-select-value-renderer';
export function FormMultiSelect({ name, control, defaultValue, error, placeholder, rules, shouldUnregister, onChange, styles, ...props }) {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const fieldError = error ?? fieldState.error?.message;
    return (_jsx(XenMultiSelect, { error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, inputWrapperOrder: ['label', 'description', 'error', 'input'], onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e);
        }, placeholder: value && value.length ? undefined : placeholder, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...styles }, value: Array.isArray(value) ? value : [], ...field, ...props }));
}
FormMultiSelect.ValueRenderer = FormMultiSelectValueRenderer;
